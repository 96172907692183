import React, {useEffect, useState} from "react"

import styles from "./ThankYou.module.scss"

interface ThankYouProps {
	name: string
	customText?: string
	customThankText?: string
}

const ThankYou: React.FC<ThankYouProps> = ({
	name,
	customText = "We have received your submission and will get back to you shortly.",
	customThankText = "Thank You, ",
}) => {
	const [show, setShow] = useState(false)

	useEffect(() => {
		setShow(true)
	}, [])

	return (
		<div
			className={
				show ? styles.wrapper : `${styles.wrapper} ${styles.hide}`
			}
		>
			<div className={styles.container}>
				<h2 className={styles.title}>
					{customThankText}
					{name}!
				</h2>
				<p className={styles.message}>{customText}</p>
			</div>
		</div>
	)
}

export default ThankYou
