import {useLocale} from "@components/Contexts/LocaleContext"
import {translate} from "@helpers/translate"

export type ValidationRule = {
	validate: (value: string | File | null) => boolean
	message: string
}

export const minLengthRule = (minLength: number): ValidationRule => {
	const {locale} = useLocale()
	return {
		validate: (value: string | File | null) =>
			(typeof value === "string" && value.trim().length >= minLength) ||
			(value instanceof File && value.size >= minLength),
		message: `${translate(
			"Minimum length is",
			locale,
			true,
		)} ${" "} ${minLength} ${translate("characters", locale)}`,
	}
}

export const maxLengthRule = (maxLength: number): ValidationRule => {
	const {locale} = useLocale()

	return {
		validate: (value: string | File | null) =>
			(typeof value === "string" && value.trim().length <= maxLength) ||
			(value instanceof File && value.size <= maxLength),
		message: `${translate(
			"Maximum length is",
			locale,
			true,
		)} ${" "}${maxLength} ${translate("characters", locale)}`,
	}
}

export const requiredRule: ValidationRule = {
	validate: (value: string | File | null) =>
		(typeof value === "string" && value.trim().length > 0) ||
		(value instanceof File && value.size > 0),
	message: "This field is required",
}

export const fileSizeRule = (maxSizeInBytes: number): ValidationRule => ({
	validate: (value: string | File | null) => {
		if (value instanceof File) {
			return value.size <= maxSizeInBytes
		} else if (value !== null) {
			return false // Return false when it's not a file, indicating an error
		}
		return true // Pass the rule when the value is null (no file selected)
	},
	message: `File size should not exceed ${maxSizeInBytes / (1024 * 1024)} MB`,
})

export const numericOnlyRule: ValidationRule = {
	validate: (value: string | File | null) =>
		typeof value === "string" && /^\d+$/.test(value),
	message: "Please enter only numbers",
}

export interface ErrorResponse {
	message: string
	fields: Record<string, string>
}

export const prepareErrorResponse = (error: any): ErrorResponse => {
	const response: ErrorResponse = {
		message: error.message,
		fields: {},
	}

	if (error.errors) {
		for (const fieldError of error.errors) {
			const fieldName = fieldError.message.match(/'fields\.(.*?)'/)?.[1]
			// const errorType = fieldError.errorType

			if (fieldName) {
				const validationRules: ValidationRule[] = []

				// if (errorType === "REQUIRED_FIELD") {
				validationRules.push(requiredRule)
				// }

				const errorMessages = validationRules
					.filter((rule) => !rule.validate(""))
					.map((rule) => rule.message)

				response.fields[fieldName] = errorMessages.join(". ")
			}
		}
	}

	return response
}

export const prepareErrorResponseForCustomForm = (
	error: any,
): ErrorResponse => {
	const response: ErrorResponse = {
		message: error.message,
		fields: {},
	}

	if (error.errors) {
		for (const fieldError of error.errors) {
			response.fields[Object.keys(fieldError)[0]] =
				fieldError[Object.keys(fieldError)[0]]
		}
	}

	return response
}
